.message-list {
  height: 450px;
  overflow: auto;
  background-color: #ecece8;
}
.rce-mbox.rce-mbox-right {
  background: #faf745;
}

.rce-mbox-text {
  white-space: pre-wrap;
  max-width: 300px;
}

.rce-mbox-file--text {
  max-width: 300px;
}

.rce-mbox-photo {
  cursor: pointer;
}

.rce-button:disabled {
  background-color: gray !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
    // max-width: none;
    // margin: 0;
    // height: 100%;
  }
  // .modal-content{
  //   height: 100%;
  // }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

@media (max-width: 576px) {
  .modal-dialog {
    margin: 0;
  }
  .tab-content .tab-pane {
    padding: 0;
  }
}

.firebaseui-id-page-password-sign-up {
  .firebaseui-id-submit {
    display: none !important;
  }
  .firebaseui-card-header {
    display: none !important;
  }
  .firebaseui-card-content {
    display: none !important;
  }
}

/* The STAR of the show */
.firebaseui-id-submit:only-child {
  display: inline-block !important;
}

.main .container-fluid {
  padding: 0 8px;
}

.no-border td {
  border-top: none;
}

.table-button-right {
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 8px;
  float: right;
}

.table-pagination-right {
  margin-top: 8px;
  float: right;
}

.table-content-center {
  text-align: center;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.input-range__slider {
  background: $primary;
  border: 1px solid $primary;
}

.input-range__track--active {
  background: $primary;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.modal-xxl {
  max-width: 1000px;
}
.modal-xxxl {
  max-width: 1250px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

#trophy-gold {
  color: #ffd700;
}

#trophy-silver {
  color: #bec0c1;
}

#trophy-bronze {
  color: #bd993b;
}

#login-page {
  // background: linear-gradient(to bottom right, $primary-light, $primary-dark);
}

#login-page .app-footer {
  background: #00000000;
  border-top: none;
  color: white;
}

#login-page .app-footer a {
  color: white;
}

#dashboard-time {
  background: linear-gradient(to bottom, $primary-light, $primary-dark);
}

.cursor {
  cursor: move;
}

.media__modal--black {
  .modal-content {
    background-color: black;
  }
}

.qr__modal {
  text-align: center;
}

.media__thumbnail {
  width: 100px;
  height: 100px;
  border: 1px solid lightgray;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
  }
  button {
    position: absolute;
    top: -2px;
    left: 18px;
  }
}

.media__thumbnail:hover button {
  opacity: 1;
}

.media__thumbnail button {
  opacity: 0;
}

.tabcontent--noborder {
  border: none;
}

.nav--bottomborder {
  margin-bottom: 1px;
  border-bottom: 1px solid #dee2e6;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  border: none;
  color: black;
}

.sortableHelper {
  z-index: 1060;
  list-style-type: none;
}

// todo change to custom class for customer chat form header dropdown
.formHeader .react-selectize-search-field-and-selected-values {
  max-height: 30px;
}

.counter-card {
  input {
    font-size: 0.75rem;
  }
  .input-group-text {
    font-size: 0.75rem;
  }
}

.custom-control-checkbox {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.inspection-select {
  z-index: 2000;
}

.chart-scrollable {
  overflow-x: scroll;
}

.chartjs-render-monitor {
  height: 250px !important;
}

.line-chart .chartjs-render-monitor {
  height: 650px !important;
}

.pie-height-increased .chartjs-render-monitor {
  height: 280px !important;
}

.borderless-button {
  margin-top: 4px;
  border: none;
  background-color: transparent;
}
.card-cross-button {
  padding: 5px;
  color: #ffb3b3;
  &:hover {
    color: red;
  }
}
